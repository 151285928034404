// app.config.ts
export default defineAppConfig({
  nuxtIcon: {
    class: "",
  },
  ui: { notifications: {
      // Show toasts at the top right of the screen
      //position: 'lg:top-0 lg:justify-start'
      position: 'top-center'
    }}
});
