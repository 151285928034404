import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as _usersdSERAzDRZpMeta } from "/app/pages/admin/_users.vue?macro=true";
import { default as usersgSsTxNnO5UMeta } from "/app/pages/admin/users.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as grv6QGp1ghzuKMeta } from "/app/pages/dashboard/grv.vue?macro=true";
import { default as mainvBBAIQniBnMeta } from "/app/pages/dashboard/main.vue?macro=true";
import { default as _91id_93s2EZ3OwGHnMeta } from "/app/pages/gbv/[id].vue?macro=true";
import { default as escalatedI1rqaqYswkMeta } from "/app/pages/gbv/escalated.vue?macro=true";
import { default as investigatebnU2R1nIjXMeta } from "/app/pages/gbv/investigate.vue?macro=true";
import { default as openr6tYCzPje9Meta } from "/app/pages/gbv/open.vue?macro=true";
import { default as resolvedBbqkqqAUJcMeta } from "/app/pages/gbv/resolved.vue?macro=true";
import { default as statusdh2MNKpTQrMeta } from "/app/pages/gbv/status.vue?macro=true";
import { default as tmpyRytzuSekCMeta } from "/app/pages/gbv/tmp.vue?macro=true";
import { default as _91id_93j2YPxmYHEKMeta } from "/app/pages/grievances/[id].vue?macro=true";
import { default as escalatedUturjBt3teMeta } from "/app/pages/grievances/escalated.vue?macro=true";
import { default as investigateJ8gk0eDoU7Meta } from "/app/pages/grievances/investigate.vue?macro=true";
import { default as open2Sub2vI4GKMeta } from "/app/pages/grievances/open.vue?macro=true";
import { default as resolvedTA1aNQXSSYMeta } from "/app/pages/grievances/resolved.vue?macro=true";
import { default as statusxvcpiziyKlMeta } from "/app/pages/grievances/status.vue?macro=true";
import { default as tabsFFBT7T4gWaMeta } from "/app/pages/grievances/tabs.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as maintenanceM1L3mPagESMeta } from "/app/pages/maintenance.vue?macro=true";
import { default as pricingpTmAIboEh0Meta } from "/app/pages/pricing.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
import { default as reportdPmEn6igepMeta } from "/app/pages/report.vue?macro=true";
import { default as signinUhHmpbjN2pMeta } from "/app/pages/signin.vue?macro=true";
import { default as _91id_93uiJE1ez9hvMeta } from "/app/pages/status/[id].vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutu8Sb32ihwPMeta || {},
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "admin-_users",
    path: "/admin/_users",
    meta: _usersdSERAzDRZpMeta || {},
    component: () => import("/app/pages/admin/_users.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: usersgSsTxNnO5UMeta || {},
    component: () => import("/app/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactV6oPJjs9RyMeta || {},
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-grv",
    path: "/dashboard/grv",
    component: () => import("/app/pages/dashboard/grv.vue").then(m => m.default || m)
  },
  {
    name: "dashboard-main",
    path: "/dashboard/main",
    meta: mainvBBAIQniBnMeta || {},
    component: () => import("/app/pages/dashboard/main.vue").then(m => m.default || m)
  },
  {
    name: "gbv-id",
    path: "/gbv/:id()",
    meta: _91id_93s2EZ3OwGHnMeta || {},
    component: () => import("/app/pages/gbv/[id].vue").then(m => m.default || m)
  },
  {
    name: "gbv-escalated",
    path: "/gbv/escalated",
    meta: escalatedI1rqaqYswkMeta || {},
    component: () => import("/app/pages/gbv/escalated.vue").then(m => m.default || m)
  },
  {
    name: "gbv-investigate",
    path: "/gbv/investigate",
    meta: investigatebnU2R1nIjXMeta || {},
    component: () => import("/app/pages/gbv/investigate.vue").then(m => m.default || m)
  },
  {
    name: "gbv-open",
    path: "/gbv/open",
    meta: openr6tYCzPje9Meta || {},
    component: () => import("/app/pages/gbv/open.vue").then(m => m.default || m)
  },
  {
    name: "gbv-resolved",
    path: "/gbv/resolved",
    meta: resolvedBbqkqqAUJcMeta || {},
    component: () => import("/app/pages/gbv/resolved.vue").then(m => m.default || m)
  },
  {
    name: "gbv-status",
    path: "/gbv/status",
    meta: statusdh2MNKpTQrMeta || {},
    component: () => import("/app/pages/gbv/status.vue").then(m => m.default || m)
  },
  {
    name: "gbv-tmp",
    path: "/gbv/tmp",
    meta: tmpyRytzuSekCMeta || {},
    component: () => import("/app/pages/gbv/tmp.vue").then(m => m.default || m)
  },
  {
    name: "grievances-id",
    path: "/grievances/:id()",
    meta: _91id_93j2YPxmYHEKMeta || {},
    component: () => import("/app/pages/grievances/[id].vue").then(m => m.default || m)
  },
  {
    name: "grievances-escalated",
    path: "/grievances/escalated",
    meta: escalatedUturjBt3teMeta || {},
    component: () => import("/app/pages/grievances/escalated.vue").then(m => m.default || m)
  },
  {
    name: "grievances-investigate",
    path: "/grievances/investigate",
    meta: investigateJ8gk0eDoU7Meta || {},
    component: () => import("/app/pages/grievances/investigate.vue").then(m => m.default || m)
  },
  {
    name: "grievances-open",
    path: "/grievances/open",
    meta: open2Sub2vI4GKMeta || {},
    component: () => import("/app/pages/grievances/open.vue").then(m => m.default || m)
  },
  {
    name: "grievances-resolved",
    path: "/grievances/resolved",
    meta: resolvedTA1aNQXSSYMeta || {},
    component: () => import("/app/pages/grievances/resolved.vue").then(m => m.default || m)
  },
  {
    name: "grievances-status",
    path: "/grievances/status",
    meta: statusxvcpiziyKlMeta || {},
    component: () => import("/app/pages/grievances/status.vue").then(m => m.default || m)
  },
  {
    name: "grievances-tabs",
    path: "/grievances/tabs",
    meta: tabsFFBT7T4gWaMeta || {},
    component: () => import("/app/pages/grievances/tabs.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceM1L3mPagESMeta || {},
    component: () => import("/app/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingpTmAIboEh0Meta || {},
    component: () => import("/app/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registermu8R7rSP5uMeta || {},
    component: () => import("/app/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "report",
    path: "/report",
    meta: reportdPmEn6igepMeta || {},
    component: () => import("/app/pages/report.vue").then(m => m.default || m)
  },
  {
    name: "signin",
    path: "/signin",
    component: () => import("/app/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: "status-id",
    path: "/status/:id()",
    meta: _91id_93uiJE1ez9hvMeta || {},
    component: () => import("/app/pages/status/[id].vue").then(m => m.default || m)
  }
]